import React from "react"
import Layout from "../components/layout"
import { Text, Title, Section } from "gatsby-theme-material-foundry"
import { Container, makeStyles, Box } from "@material-ui/core"
import SEO from "../components/SEO/seo"
import List from "../components/list"
import Form from "../components/forms/rektors-utfordring"

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: "200px",
  },
  yellow: {
    color: theme.palette.error.main,
    textDecoration: "underline",
  },
  root: {
    backgroundColor: "white",
    padding: "10px",
    "& label.Mui-focused": {
      color: theme.palette.info.main,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.info.main,
      },
    },
  },
}))

export default function StoreArrangement() {
  const classes = useStyles()

  return (
    <Layout>
      <SEO postTitle="Rektors utfordring" />
      <Section black>
        <Container maxWidth="lg">
          <Box pt={20} pb={8}>
            <Box>
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                  paddingTop: "56.25%",
                }}
              >
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    border: "0",
                    boxShadow:
                      "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                  }}
                  width="800"
                  height="400"
                  title="Rektors Utfordring video"
                  src="https://www.youtube.com/embed/y9Nrf65eEcg"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </Box>
          </Box>
        </Container>
      </Section>
      <Container maxWidth="md">
        <Box>
          <Title variant="h2" primary>
            Rektors utfordring
          </Title>
          <Box my={4}>
            <Text paragraph>
              HVLs viktigste bidrag til en mer bærekraftig verden er uten tvil å
              utdanne endringsagenter som vi utstyrer med verktøy til å endre
              næringslivet og offentlig sektor i en mer bærekraftig retning, og
              at våre forskere utvikler ny kunnskap og miljøvennlig teknologi.
            </Text>
            <Text paragraph>
              Samtidig kan vi også selv bli bedre. For oss handler ikke
              bærekraft bare om at vi skal tilby en utdanning som skal gi
              fremtidens arbeidstakere verktøy til å imøtekomme
              bærekraftsutfordringene. Vi skal også være en høgskole hvor
              bærekraft er styrende for hvordan vi organiserer og drifter
              institusjonen.
            </Text>
            <Text paragraph>
              Vi har blitt en stor institusjon med over 16000 studenter og 1600
              ansatte. Våre ansatte reiser på internasjonale konferanser og
              våre studenter drar på utveksling til hele verden. I tillegg er vi
              med våre fem regionale campus en institusjon med stor geografisk
              spredning og mange bygninger. Dette gir oss utfordringer med å
              holde et lavt karbonfotavtrykk og fordrer at vi kan ta i bruk nye
              og bedre måter å tenke undervisning, kommunikasjon, gjenbruk og
              samferdsel på.
            </Text>
            <Text paragraph>
              Vi vet at studentene våre er en ressurs med evne til å tenke
              nytt, og med et like brennende ønske om å ta ansvar for vår felles
              fremtid som HVL. Vi ønsker derfor å utfordre dere til å hjelpe oss
              til å sammen skape en mer bærekraftig høgskole, samt styrke HVLs
              regionale rolle som pådriver for bærekraft. Rektoratets utfordring
              for 2020 er følgende:
            </Text>
            <Title variant="h4" black>
              Hvordan kan HVLs studenter og ansatte bidra til et mer bærekraftig
              samfunn?
            </Title>
          </Box>

          <Box my={2}>
            <Text variant="h5">
              Vi vil at studentene skal få bruke vårsemesteret på å tenke på
              denne utfordringen. Send inn din idé før{" "}
              <span className={classes.yellow}>20. mai 2020.</span> Vinnerne
              presenteres i juni 2020.
              <List
                iconColor="primary"
                items={[
                  { text: "1. plass premieres med 10 000 kroner" },
                  { text: "2.plass med 5000 kroner" },
                  { text: "3. og 4. plass får 2500 kroner hver" },
                ]}
              />
            </Text>

            <Container maxWidth="sm">
              <Title variant="h3" black align="center">
                Skjema for bidrag
              </Title>
              <Text primary gutterBottom>
                <strong>NB!</strong> Studenter kan også svare på utfordringen i
                form av en kort video, en powerpoint, et kort notat, eller en
                annen ønskelig form. Send inn ditt bidrag som et vedlegg til
                <a href="mailto:skape@hvl.no" style={{ marginLeft: "4px" }}>
                  skape@hvl.no
                </a>{" "}
                (om filen er for stor, last den gjerne opp på en skytjeneste
                eller annen plattform og del så linken med oss).
              </Text>
              <Form />
            </Container>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
