import React from "react"
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core"
import StarIcon from "@material-ui/icons/Star"

function GenerateList({
  items,
  color
}) {
return(
<List>
{items && items.map(item => 
<ListItem>
<ListItemIcon>
              <StarIcon color="primary"/>
            </ListItemIcon>
    <ListItemText primary={item.text}/>
</ListItem>)}
</List>
)
  }




export default GenerateList
