import React from "react"
import {Box, FormHelperText, FormLabel, FormControlLabel, FormControl, Checkbox} from "@material-ui/core"

function PersonVern({errors, values, handleChange}){
    return(
        <Box mt={2}>
        <FormControl required error={Boolean(errors.checked)} component="fieldset">   
        <FormLabel component="legend">Personværnerklæring</FormLabel>
        <FormControlLabel
          control={<Checkbox checked={values.checked}  onChange={handleChange} value="checked" name="checked" color="primary"/>}
          label={<a href="https://hvlskape.no/personvern" target="_blank" rel="noopener noreferrer">Jeg godkjenner personværnerklæringen</a>}
        />
          <FormHelperText>{errors.checked}</FormHelperText>
        </FormControl>
        </Box>
    )
}

export default PersonVern;